// redux
import { getJwtToken, getUserInfoJWT, sessionExists, setSession } from 'store/slices/authJwt/helpers'
import axios from 'axios'
import axiosInstance from 'utils/axios'
import { getAvatar, loginSuccess, logoutSuccess, registerSuccess, startLoading, stopLoading } from 'store/slices/authJwt/authJwt'
import { fetchCompanyHierarchyList2, fetchCompanySettings, getCompanySubscription } from 'store/slices/company'
import { fetchFilterData } from 'store/slices/filters'
import { Dispatch } from 'redux'
import { loadColorIntensity } from 'store/slices/reports/colorIntensity'
import { getImpactGeographyList, getInitiativeDivisionThemesList, getInitiativeDivisionThemesMappingRecords, getTagList } from 'store/slices/initiative'
import { getPartnerGeography, getPartnerOrganization, getPartnerScales, getPartnerStakeholderList } from 'store/slices/impact/partner'
import { useAppDispatch, useAppSelector } from 'hooks/useAppRedux'
import { User } from '../@types/account'
import { NWPCA, PCA } from 'msa'

// ----------------------------------------------------------------------
import { browserInfo } from 'utils/browserInfo'
import { isEmpty } from 'lodash'
import { fetchUserSettings } from '../store/slices/settings'
import { getActivity } from '../store/slices/impact/activity'
import { fetchInsightsData } from '../store/slices/insights'
import { addMonths, endOfDay, endOfQuarter, startOfDay, startOfQuarter } from 'date-fns'

type LoginParams = {
    email: string
    password: string
    isRememberMe: boolean
    captchaToken?: string
}

type VerifyParams = {
    email: string
    code: string
}

type RegisterParams = {
    email: string
    password: string
    name: string
    companyName: string
    captchaToken: any
}
type ChangePasswordParams = {
    email: string
    code: string
    password: string
    confirmPassword: string
}

export const userLogoutCleanUp = () => {
    //sessionStorage.removeItem('sessionId')
    sessionStorage.clear()
    //localStorage.removeItem('userLocation')
    localStorage.clear()
    try {
        // amplitude.getInstance().clearUserProperties()
    } catch (_) {
        //
    }
    setSession(null)
}

export const logoutFn = (dispatch: (arg: any) => void) => {
    dispatch(startLoading())

    dispatch(async () => {
        try {
            await PCA.logoutRedirect({
                // Return false if you would like to stop navigation after local logout
                onRedirectNavigate: () => false,
            }).then()
        } catch (_) {
            // ignore
        }
        try {
            await NWPCA.logoutRedirect({
                // Return false if you would like to stop navigation after local logout
                onRedirectNavigate: () => false,
            })
        } catch (_) {
            // ignore
        }
    })

    axiosInstance
        .get('/umm/api/account/LogOff')
        .then(() => {
            userLogoutCleanUp()
            dispatch(logoutSuccess())
        })
        .catch(() => {
            // pass
        })
        .finally(() => {
            dispatch(stopLoading())
        })
}

export const updateUserSession = (user: Partial<User>, logout: VoidFunction) => {
    let userLocation = { lat: '', lon: '', query: '', country: '' } /// localStorage.getItem('userLocation')||
    try {
        userLocation = JSON.parse(localStorage.getItem('userLocation') || '{}')
    } catch (_err) {
        /// pass
        userLocation = { lat: '', lon: '', query: '', country: '' }
    }
    const browserData = browserInfo()
    const localSessionId = sessionStorage.getItem('sessionId') || ''
    axiosInstance
        .post('/umm/api/Account/CreateUpdateUserSession?request=d', {
            UserID: user?.id,
            SessionID: localSessionId,
            Latitude: userLocation?.lat || '',
            Longitude: userLocation?.lon || '',
            IPAddress: userLocation?.query || '',
            Country: userLocation?.country || '',
            Email: user?.email,
            Username: user?.contactName,
            Status: true,
            VisitCount: 0,
            IsDeleted: false,
            Browser: browserData.browserName,
            ClientOS: browserData.browserPlatform,
            LogoutTime: null,
            Id: '00000000-0000-0000-0000-000000000000',
            CreatedAt: null,
            LastModifiedAt: null,
        })
        .then(({ data }) => {
            const { sessionId } = data?.rm || {}
            if (isEmpty(localSessionId) && !isEmpty(sessionId)) {
                sessionStorage.setItem('sessionId', sessionId)
            }
            if (getJwtToken() !== data?.responseData?.accessToken) {
                logout()
            }
            /// nothing
        })
        .catch((error) => {
            if (axios.isAxiosError(error) || error?.response?.status) {
                const status = error?.response?.status
                if (status === 401 && sessionExists()) {
                    logout()
                }
            }
        })
}

export function loadAppForUser(access_token: string | null) {
    // @ts-expect-error: NEED better Typescript descr
    return async (dispatch: Dispatch<any>, getState) => {
        setSession(access_token)
        const user = getUserInfoJWT()
        dispatch(loginSuccess({ user }))

        if (user?.userRole === 'Service Admin') {
            return
        }

        dispatch(fetchInsightsData(addMonths(startOfQuarter(startOfDay(new Date())), -4), endOfQuarter(endOfDay(new Date()))))

        await Promise.all([
            dispatch(fetchUserSettings()),
            dispatch(fetchCompanySettings()),
            dispatch(getCompanySubscription()),
            dispatch(getAvatar()),
            dispatch(loadColorIntensity()),
            dispatch(fetchFilterData()),
            dispatch(getPartnerOrganization()),
            dispatch(getPartnerGeography()),
            dispatch(getImpactGeographyList()),
            dispatch(getPartnerScales()),
            dispatch(getPartnerStakeholderList()),
            dispatch(getInitiativeDivisionThemesList()),
            dispatch(getInitiativeDivisionThemesMappingRecords()),
            dispatch(fetchCompanyHierarchyList2()),
            dispatch(getTagList()),
            dispatch(getActivity()),
        ])

        updateUserSession(user, () => logoutFn(dispatch))

        const companySettings = getState().company.settings

        try {
            // @ts-expect-error: NEED better Typescript descr
            window.Bugsnag?.setUser(user.id, user.email, user.contactName)
        } catch (_) {
            ///
        }

        import('@hotjar/browser')
            .then(({ default: Hotjar }) => {
                const siteId = 3575100
                const hotjarVersion = 6
                try {
                    Hotjar.init(siteId, hotjarVersion)
                    Hotjar.identify(user?.id || '', { email: user?.email || '', contactName: user?.contactName || '' })
                } catch (_) {
                    // console.log('Hotjar Error', _)
                }
            })
            .catch((err) => {
                console.log(err)
            })

        try {
            // @ts-expect-error: NEED better Typescript descr
            Bonboarding.identify({
                uniqueID: user?.id || '',
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                company: companySettings?.Name || '',
                email: user?.email,
                signUpDate: new Date().toISOString(),
            })
        } catch (_err) {
            /// pass
        }

        try {
            const HelpCrunchUser = {
                email: user?.email || '',
                name: user?.contactName || '',
                ///phone: data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] || '',
                company: companySettings?.Name || '',
                user_id: user?.id || '',
            }

            // @ts-expect-error: NEED better Typescript descr
            window?.HelpCrunch('init', 'changecompass', {
                applicationId: 1,
                applicationSecret: 'lLXWL5Vuqzfo+8RCWY7w8z8EQ0rOoaSPCs3R7QIL/wZzd7utU7JtlLov1rVMQKo+yfFcnICUuR3UjFD9kywTvg==',
                user: HelpCrunchUser,
            })

            // @ts-expect-error: NEED better Typescript descr
            window?.HelpCrunch('updateUser', HelpCrunchUser)
            // @ts-expect-error: NEED better Typescript descr
            window?.HelpCrunch('showChatWidget')
        } catch (e) {
            console.error(e)
        }

        try {
            // @ts-expect-error: NEED better Typescript descr
            window.Userback = window?.Userback || {}

            // @ts-expect-error: NEED better Typescript descr
            window.Userback?.init('32108|58441|GpIAjfRO72CAheWg8N7sZlloH', {
                email: user?.email || '',
                name: user?.contactName || '',
            })

            // @ts-expect-error: NEED better Typescript descr
            window.Userback.identify(user.id, {
                email: user.email || '',
                name: user.contactName || '',
                full_name: user.contactName || '',
                account_id: user.id || '',
                company: companySettings?.Name || '',
            })

            // @ts-expect-error: NEED better Typescript descr
            window.Userback.setData({
                email: user?.email || '',
                name: user?.contactName || '',
                full_name: user?.contactName || '',
                account_id: user?.id || '',
                company: companySettings?.Name || '',
            })
        } catch (_e) {
            ///
        }
        // @ts-expect-error: NEED better Typescript descr
        window?.pendo?.initialize({
            visitor: {
                id: user?.id || '',
                email: user?.email || '',
                full_name: user?.contactName || '',
                company: companySettings?.Name || '',
            },
            account: {
                id: user?.id || '',
                name: user?.contactName || '',
                company: companySettings?.Name || '',
            },
        })

        // setAuthenticated
    }
}

export interface useAuthProps {
    method: string
    user: User
    isCompanyAdmin: boolean
    isSuperAdmin: boolean
    isViewer: boolean
    isCompanyUser: boolean
    isLoading: boolean
    isAuthenticated: boolean
    login: (data: LoginParams) => void
    register: (data: RegisterParams) => void
    verify: (data: VerifyParams) => void
    resend: ({ email }: { email: string }, callback?: (msg: string) => void) => void
    logout: () => void
    resetPassword: (email: any, captchaToken: any, callback?: (msg: string) => void) => void
    verifyForgot: (data: VerifyParams) => void
    changePassword: (data: ChangePasswordParams, callback?: (msg: string) => void) => void
}

export default function useAuth(): useAuthProps {
    // JWT Auth
    const dispatch = useAppDispatch()
    const { user, isLoading, isAuthenticated } = useAppSelector((state) => state.authJwt)

    // JWT Auth
    return {
        method: 'jwt',
        user,
        isCompanyAdmin: user?.userRole === 'Company Admin',
        isSuperAdmin: user?.userRole === 'Service Admin',
        isViewer: user?.userRole === 'Company Viewer',
        isCompanyUser: user?.userRole === 'Company User',
        isLoading,
        isAuthenticated,

        login: async ({ email, password, isRememberMe, captchaToken = '' }: LoginParams) => {
            const response = await axios.post('/umm/api/account/login', {
                email,
                password,
                Password: password, /// REMOVE ME
                isRememberMe,
                captchaToken,
            })
            const data = response?.data?.responseData || response.data || {}

            const { access_token: accessToken } = data
            if (!accessToken) {
                throw new Error(response?.data?.message || data?.message)
            }
            await dispatch(startLoading())
            await dispatch(loadAppForUser(accessToken))
            await dispatch(stopLoading())
        },

        register: ({ email, password, companyName, captchaToken }: RegisterParams) =>
            dispatch(async () => {
                await axios.post('/umm/api/account/Register', {
                    email,
                    password,
                    Password: password,
                    confirmPassword: password,
                    name: companyName,
                    companyName,
                    captchaToken,
                })
                //const {accessToken, user} = response.data;
                window.localStorage.setItem('registerEmail', email)
                dispatch(registerSuccess({ user: { email } }))
            }),
        verify: ({ email, code }: VerifyParams) =>
            dispatch(async () => {
                const response = await axios.post('/umm/api/account/ConfirmEmail', {
                    userId: email,
                    code,
                })
                const { access_token: accessToken } = response?.data?.responseData || response.data
                dispatch(loadAppForUser(accessToken))
            }),
        resend: ({ email }: { email: string }, callback?: (msg: string) => void) =>
            dispatch(async () => {
                const response = await axios.get('/umm/api/account/ReSendEmailConfirm', {
                    params: { emailAddress: email },
                })
                callback?.(response.data?.message)
            }),

        logout: () => logoutFn(dispatch),
        resetPassword: (email: any, captchaToken: any, callback?: (msg: string) => void) =>
            dispatch(async () => {
                const response = await axios.post('/umm/api/account/Forgotpassword', {
                    email,
                    captchaToken,
                })
                callback?.(response?.data?.message)
            }),

        verifyForgot: ({ email, code }: VerifyParams) =>
            dispatch(async () => {
                await axios.post('/umm/api/account/ResetPassword', {
                    userId: email,
                    code,
                })
            }),
        changePassword: ({ email, code, password }: ChangePasswordParams, callback?: (msg: string) => void) =>
            dispatch(async () => {
                try {
                    const response = await axios.post('/umm/api/account/ResetPassword', {
                        userId: email,
                        code,
                        Password: password,
                        confirmPassword: password,
                    })
                    if (response?.data?.status !== 1) {
                        throw Error(response?.data?.message)
                    }
                    callback?.(response?.data?.message || 'Opps some error ocurred')
                } catch (err) {
                    // @ts-expect-error: NEED better Typescript descr
                    const message = err?.response?.data?.message
                    // @ts-expect-error: NEED better Typescript descr
                    const userNoFound = err?.response?.data?.responseData['$.userId'] || null

                    if (userNoFound) {
                        throw Error('User not found')
                    }
                    throw Error(message || 'Failed to change password')
                }
            }),
    }
}
