import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isEmpty, maxBy } from 'lodash'
import { AIDGroup, AIDGroupGrouped, ImpactAid } from '../../../@types/impact'
import { createSelector } from 'reselect'
import { RootState } from 'store/store'
import { isGuid } from 'utils/string'
import { filterEmpty } from '../../../utils/array'

export const aidType = (aidType: number): string => {
    switch (aidType) {
        case 1:
            return 'low'
        case 2:
            return 'medium'
        case 3:
            return 'high'
        default:
            return 'unknown'
    }
}

interface initialStateProps {
    isLoading: boolean
    saving: boolean
    error: Record<string, any> | null
    aids: AIDGroup[]
}

const initialState: initialStateProps = {
    isLoading: false,
    saving: false,
    error: null,
    aids: [],
}

const slice = createSlice({
    name: 'aids',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        stopLoading(state) {
            state.isLoading = false
        },

        setSaving(state, action) {
            state.saving = action.payload
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        consumeError(state) {
            state.error = initialState.error
        },

        // GET PRODUCTS
        getAidsSuccess(state, action) {
            state.isLoading = false
            state.aids = action.payload
        },
    },
})

export default slice.reducer

export const consumeError = () => (dispatch: Dispatch<any>) => dispatch(slice.actions.consumeError())

export const hasEnabledAIDS = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): boolean => aids.some((aid: AIDGroup) => aid.Enable)
)

export const allAids = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): AIDGroup[] => aids
)

export const hasImpactAids = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): boolean => isEmpty(aids)
)

export const impactAidError = createSelector(
    (state: RootState) => state.impact.aids.error,
    (error): Record<string, any> | null => error
)

const buildTree = (aids: AIDGroup[]): Record<string, AIDGroupGrouped> => {
    const defaultGrouped = { high: null, medium: null, low: null }
    let grouped = { aids: { ...defaultGrouped } } as Record<string, AIDGroupGrouped>
    let existing = {} as Record<string, Record<string, AIDGroup[]>>
    aids.forEach((aid: AIDGroup) => {
        const currentAidType = aidType(aid.Type)
        if (isEmpty(aid.TypeIds)) {
            const alreadyThere = grouped['aids'][currentAidType]
            if (alreadyThere) {
                if (isEmpty(alreadyThere.ImpactAids)) {
                    deleteAidGroup(alreadyThere)
                        .then(() => console.log('deleted duplicated aid', alreadyThere))
                        .catch((e) => {
                            console.error('error deleting aid', e)
                        })
                    return
                }
                if (alreadyThere.ImpactAids.length > aid.ImpactAids.length) {
                    existing = {
                        ...existing,
                        ['aids']: {
                            ...(existing[currentAidType] || {}),
                            [aid.Id]: [aid, ...(existing[currentAidType]?.[aid.Id] || [])],
                        },
                    }
                    return
                }
            }
            //deleteAidGroup(aid).then(() => console.log('deleted duplicated aid', alreadyThere))
            grouped['aids'] = { ...(grouped['aids'] || { ...defaultGrouped }), [currentAidType]: aid }
            return
        }

        aid.TypeIds.forEach((typeId: string) => {
            const alreadyThere = grouped['aids'][currentAidType]
            if (alreadyThere) {
                if (isEmpty(alreadyThere.ImpactAids)) {
                    deleteAidGroup(alreadyThere)
                        .then(() => console.log('deleted duplicated aid', alreadyThere))
                        .catch((e) => {
                            console.error('error deleting aid', e)
                        })
                    return
                }
                if (alreadyThere.ImpactAids.length > aid.ImpactAids.length) {
                    if (alreadyThere.ImpactAids.length > aid.ImpactAids.length) {
                        existing = {
                            ...existing,
                            [typeId]: {
                                ...(existing[currentAidType] || {}),
                                [aid.Id]: [aid, ...(existing[currentAidType]?.[aid.Id] || [])],
                            },
                        }
                    }
                    return
                }
            }
            grouped[typeId] = { ...(grouped[typeId] || { ...defaultGrouped }), [currentAidType]: aid }
        })
    })
    if (Object.values(existing).length > 0) {
        Object.entries(existing).forEach(([group, values]) => {
            Object.entries(values).forEach(([key, value]) => {
                grouped = {
                    ...grouped,
                    [group]: {
                        ...grouped[group],
                        [key]:
                            maxBy<AIDGroup>(filterEmpty([...value, (grouped[group] || {})[key] as AIDGroup]), (v: AIDGroup) => v.ImpactAids.length) ||
                            (grouped[group] || {})[key],
                    },
                }
            })
        })
    }
    return grouped
}

export const getAidsTree = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): Record<string, AIDGroupGrouped> => {
        return buildTree(aids)
    }
)

export const fetchAids = createAsyncThunk('thunk/getFetchAids', async (_, { dispatch }) => {
    dispatch(slice.actions.startLoading())
    try {
        const response = await axiosInstance.get('/api/CompanySettings/GetAllImpactAid?request=d')
        const data: AIDGroup[] = responseData(response.data)
        dispatch(slice.actions.getAidsSuccess(data))
        return buildTree(data)
    } catch (error) {
        dispatch(slice.actions.hasError(error))
    } finally {
        dispatch(slice.actions.stopLoading())
    }
    return []
})

export const saveAid = async (item: AIDGroup, dispatch: Dispatch): Promise<any> => {
    const { Enable, ScaleIds, Id, Type, TypeIds } = item
    const { data } = await axiosInstance.post('/api/CompanySettings/SaveImpactAidGroup?request=d', { Enable, ScaleIds, Id, Type, TypeIds })
    const id = isEmpty(data?.responseData) ? Id : data?.responseData
    if (!isGuid(id)) {
        return id
    }
    await Promise.all(
        item.ImpactAids.map((i: any) =>
            axiosInstance
                .post('/api/CompanySettings/SaveImpactAid?request=d', [{ ...i, ImpactAidGroupId: id }])
                .then(() => {
                    // do notthing
                })
                .catch(() => {
                    /// do notthing
                })
        )
    )

    const response = await axiosInstance.get('/api/CompanySettings/GetAllImpactAid?request=d')
    const data2: AIDGroup[] = responseData(response.data)
    dispatch(slice.actions.getAidsSuccess(data2))
}

export const deleteAidItem = async (item: ImpactAid) => {
    if (isEmpty(item.Id)) return
    await axiosInstance.post('/api/CompanySettings/DeleteImpactAid?request=d&Id=' + item.Id, {})
}

export const deleteAidGroup = async (item: AIDGroup) => {
    if (isEmpty(item.Id)) return
    await axiosInstance.post('/api/CompanySettings/DeleteImpactAidGroup?request=d&Id=' + item.Id, {})
}
